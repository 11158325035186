import { render, staticRenderFns } from "./Generico.vue?vue&type=template&id=799aada2&scoped=true&"
import script from "./Generico.vue?vue&type=script&lang=ts&"
export * from "./Generico.vue?vue&type=script&lang=ts&"
import style0 from "./Generico.vue?vue&type=style&index=0&id=799aada2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799aada2",
  null
  
)

export default component.exports